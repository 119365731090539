




























import { Component, Vue, Prop } from 'vue-property-decorator';

import QuestionItem from '../QuestionItem/QuestionItem.vue';

import { Question } from '../../interface';

@Component({
  components: {
    QuestionItem,
  },
})
export default class RelatedQuestions extends Vue {
  @Prop({ required: true }) questions!: Array<Question>;

  getMoreRelatedQuestions() {
    this.$emit('more-questions');
  }

  handleRedirectQuestion(questionId: number) {
    this.$router.push({
      name: 'AnswerQuestion',
      params: {
        id: String(questionId),
      },
    });
  }
}
