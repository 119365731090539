































import {
  Component, Prop, Mixins, Watch,
} from 'vue-property-decorator';

import BoxContainer from '@/components/BoxContainer/BoxContainer.vue';
import FeedbackUser from '@/components/FeedbackUser/index.vue';

import CreditInformation from './components/CreditInformation/index.vue';
import CreditBoxLoading from './CreditBoxLoading.vue';

import { InstanceAmplitude } from '@/share/Util/Metrics/Metrics';

import Credits, { ContextOptionsCredits } from '@/mixins/Credits';

import {
  NO_CREDITS,
  MAXIMUM_TWO_CREDITS,
  HAS_CREDITS,
  CONTAINER_CLASSES,
  CONTEXT_VIDEO,
  CONTEXT_QUESTIONS,
  CONTEXT_BOOK,
} from './constants';

@Component({
  components: {
    BoxContainer,
    CreditInformation,
    CreditBoxLoading,
    FeedbackUser,
  },
})
export default class CreditBox extends Mixins(Credits) {
  @Prop({ default: CONTAINER_CLASSES }) containerClasses!: string;
  @Prop({ default: ContextOptionsCredits.videos }) context!: ContextOptionsCredits;
  @Prop({ required: true }) url!: string;

  get route() {
    return this.$route.fullPath;
  }

  get remainingCredits() {
    const { credits, send_total } = this.$store.getters.creditData;

    return credits - send_total;
  }

  @Watch('route', { immediate: true })
  setCredit() {
    const contextCredits = this.context;
    this.getCredits(ContextOptionsCredits[contextCredits]);
  }

  get warningMessage() {
    if (this.remainingCredits <= 0) return NO_CREDITS;

    if (this.remainingCredits <= 2) return MAXIMUM_TWO_CREDITS;

    return HAS_CREDITS;
  }

  setTrackAmplitude() {
    const { path } = this.$route;

    InstanceAmplitude.setTrack({
      eventName: 'component_click',
      additionalAttrs: {
        data: {
          component: 'CreditBox',
          local: 'Conhecer planos',
          path,
          context: this.context === CONTEXT_VIDEO ? CONTEXT_BOOK : CONTEXT_QUESTIONS,
          remainingCredits: this.getRemainingCredits,
        },
      },
    });
  }
}
