




























































import {
  Component,
  Vue,
  Prop,
} from 'vue-property-decorator';

import LightBoxImage from '@/mixins/LightBoxImage';

import Arrow from '@/assets/icons/Arrow.vue';

import AlternativeIndex from '../AlternativeIndex/index.vue';

import { IOption, IRelateColumnsAnswers } from '@/globalInterfaces/Questions';

import { MAXIMUM_FONT_SIZE, MINIMUM_FONT_SIZE } from '@/constant/Question';

@Component({
  components: {
    AlternativeIndex,
    Arrow,
  },
  mixins: [LightBoxImage],
})
export default class AlternativeRelateColumns extends Vue {
  @Prop({ required: true }) alternatives!: Array<IOption>;
  @Prop({ default: 14 }) fontSizeProp!: number;

  private shuffledAlternatives: Array<IOption> = [];
  private shuffledResponses: Array<IRelateColumnsAnswers> = [];

  created() {
    this.shuffleAlternatives();
    this.shuffleResponses();
  }

  get fontSize() {
    if (this.fontSizeProp >= MAXIMUM_FONT_SIZE) return MAXIMUM_FONT_SIZE;
    if (this.fontSizeProp <= MINIMUM_FONT_SIZE) return MINIMUM_FONT_SIZE;
    return this.fontSizeProp;
  }

  commitAnswers() {
    this.$store.commit('setAnswersRelateColumnsQuestion', this.shuffledResponses);
  }

  shuffleAlternatives() {
    this.shuffledAlternatives = this.shuffleArray(this.alternatives).map((alternative, index) => ({ ...alternative, index: index + 1 }));

    this.$store.commit('setShuffledAlternativesRelateColumns', this.shuffledAlternatives);
  }

  shuffleResponses() {
    this.shuffledResponses = this.shuffleArray(this.alternatives.map((a) => ({
      optionID: a.ID,
      response: a.response,
      selectedIndex: null,
      indexCorrect: null,
    })));

    this.$store.commit('setAnswersRelateColumnsQuestion', this.shuffledResponses);
  }

  shuffleArray(array: any[]): any[] {
    return array.sort(() => Math.random() - 0.5);
  }
}
