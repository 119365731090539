import { render, staticRenderFns } from "./RelatedQuestionsLoading.vue?vue&type=template&id=d2edbb4e&scoped=true&"
import script from "./RelatedQuestionsLoading.vue?vue&type=script&lang=ts&"
export * from "./RelatedQuestionsLoading.vue?vue&type=script&lang=ts&"
import style0 from "./RelatedQuestionLoading.scss?vue&type=style&index=0&id=d2edbb4e&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "d2edbb4e",
  null
  
)

export default component.exports