


































import { Component, Vue, Prop } from 'vue-property-decorator';

import AlternativeIndex from '@/components/Question/AlternativeIndex/index.vue';
import FeedbackUser from '@/components/FeedbackUser/index.vue';

import { ISummationAlternative } from '@/globalInterfaces/Questions';

import { replaceText } from '@/share/Util/Text/index';

@Component({
  components: {
    AlternativeIndex,
    FeedbackUser,
  },
})
export default class ResolutionSummation extends Vue {
  @Prop({ default: true }) showContent!: boolean;

  get shuffledAlternativesCorrect() {
    const alternatives: ISummationAlternative[] = this.$store.getters.summationQuestion.shuffledAlternatives;

    return alternatives.filter((alternative: ISummationAlternative) => alternative.validated === 1);
  }

  get summationAnswer() {
    const result = this.shuffledAlternativesCorrect.reduce((acc: number, alternative: ISummationAlternative) => (acc + alternative.index), 0);

    if (!this.showContent) {
      return replaceText(String(result));
    }

    return result;
  }

  getOperator(index: number) {
    return index <= this.shuffledAlternativesCorrect.length - 1 ? ' + ' : '';
  }
}
