import AppClient from '../AppClient';

import { GetQuestions } from './IQuestion';

export default class RedactionService {
  private basePath = process.env.VUE_APP_SIMULATION_API;

  private Client = new AppClient({ url: this.basePath });

  async getQuestion(data: GetQuestions) {
    const URI = '/questions';

    const response = await this.Client.get(URI, { ...data });

    return response?.data;
  }

  async getOptions(id: number) {
    const URI = `/questions/${id}/answers`;

    const response = await this.Client.get(URI, { id });

    return response?.data;
  }
}
