import { Component, Vue } from 'vue-property-decorator';

// eslint-disable-next-line import/no-cycle
import AuthService from '@/services/Auth/AuthService';

interface Credit {
  credits: number;
  send_total: number;
  is_credits_question?: boolean;
  is_credits_video?: boolean;
}

export enum ContextOptionsCredits {
  videos = 'videos',
  questions = 'questions',
}

@Component
export default class Credits extends Vue {
  protected isLoadingCredits = true;
  protected creditData: Credit | null = null;

  private AuthService = new AuthService();

  get hasCreditsQuestion() {
    return this.creditData ? this.creditData.is_credits_question : false;
  }

  get getRemainingCredits() {
    if (!this.creditData) return 0;

    const totalCredits = this.creditData.credits;

    if (!totalCredits) return 0;

    const resultCredits = totalCredits - this.creditData.send_total;

    return resultCredits < 0 ? 0 : resultCredits;
  }

  async getCredits(contextCredits: ContextOptionsCredits) {
    try {
      this.isLoadingCredits = true;

      const creditData = await this.AuthService.getCredits(contextCredits);

      this.creditData = creditData;

      this.$store.commit('setCreditData', creditData);
    } catch (error) {
      this.$store.dispatch('Toast/setToast', {
        text: 'Erro ao carregar os créditos',
        status: 'error',
      });

      console.error(error);
    } finally {
      this.isLoadingCredits = false;
    }
  }
}
