





























import { Component, Prop, Vue } from 'vue-property-decorator';

import AlternativeIndex from '@/components/Question/AlternativeIndex/index.vue';
import FeedbackUser from '@/components/FeedbackUser/index.vue';

import { IOption } from '@/globalInterfaces/Questions';

import { FALSY, TRUTHY } from '@/constant/Question';

import { replaceText } from '@/share/Util/Text/index';

@Component({
  components: {
    AlternativeIndex,
    FeedbackUser,
  },
})
export default class ResolutionTruthyOrFalsy extends Vue {
  @Prop({ required: false }) answers!: Array<IOption>;
  @Prop({ default: true }) showContent!: boolean;

  setAnswer(option: any) {
    const result = option?.validated === 1 ? TRUTHY : FALSY;

    if (!this.showContent) {
      return replaceText(result);
    }

    return result;
  }
}
