














import { Component, Vue, Prop } from 'vue-property-decorator';

const PHRASE_UNLIMITED_CREDITS = 'Você possui créditos ilimitados.';
const CREDITS_IS_NULL = 'Você não possui créditos!';
const NAME_CREDIT = 'crédito';
const NAME_CREDITS = 'créditos';

@Component({})
export default class CreditInformation extends Vue {
  @Prop() current!: number;
  @Prop() total!: number;

  get message() {
    if (this.current === null || this.total === null) return CREDITS_IS_NULL;

    if (this.total > 9999) {
      return PHRASE_UNLIMITED_CREDITS;
    }

    const nameCredits = this.current === 1 ? NAME_CREDIT : NAME_CREDITS;

    return `Você possui ${this.current} ${nameCredits} de ${this.total}.`;
  }
}

