const NO_CREDITS = 'Seus créditos acabaram! Assine um plano e tenha acesso ilimitado.';
const MAXIMUM_TWO_CREDITS = 'Seus créditos estão acabando! Assine um plano e tenha acesso ilimitado.';
const HAS_CREDITS = 'Assine um plano e tenha acesso ilimitado.';
const CONTAINER_CLASSES = 'col-xl-3 col-md-4 col-12 order-1 order-md-2';
const CONTEXT_VIDEO = 'videos';
const CONTEXT_QUESTIONS = 'questions';
const CONTEXT_BOOK = 'book';

export {
  NO_CREDITS,
  MAXIMUM_TWO_CREDITS,
  HAS_CREDITS,
  CONTAINER_CLASSES,
  CONTEXT_VIDEO,
  CONTEXT_QUESTIONS,
  CONTEXT_BOOK,
};
